import React, { useState } from "react"
import { Modal, Button, Container } from "react-bootstrap"
import logisticEquation from "../images/equations/logistic-equation-1.svg"
import parse from "html-react-parser"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const MathModal = props => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(0)

  const pageContent = {
    title: parse(t("MathModal_title")),
    topText: parse(t("MathModal_topText")),
    bottomText: parse(t("MathModal_bottomText")),
  }

  // ============================================================================================
  // export default function modal(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Using Grid in Modal
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body className="show-grid">
        <Container>
          <Modal.Header closeButton>
            <h3>{pageContent.title}</h3>
          </Modal.Header>
          <br />

          {pageContent.topText}

          <center>
            <p>
              <img
                src={logisticEquation}
                alt={parse(t("MathModal_middleText"))}
              />
              <p className="smText">{parse(t("MathModal_imgAlt"))}</p>
            </p>
          </center>
          {pageContent.bottomText}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btnNext" variant="info" onClick={props.onHide}>
          {parse(t("Close"))}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

// example:=======================================
// function App() {
//   const [modalShow, setModalShow] = useState(false)

//   return (
//     <>
//       <Button variant="primary" onClick={() => setModalShow(true)}>
//         Launch modal with grid
//       </Button>

//       <MydModal show={modalShow} onHide={() => setModalShow(false)} />
//     </>
//   )
// }

export default MathModal
